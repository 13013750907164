import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import FileInputComponent from 'react-file-input-previews-base64'
import { useState } from 'react';

const Five = ({
  pbTitle,
  setPbTitle,
  pbFullNames,
  setPbFullNames,
  pbSurname,
  setPbSurname,
  pbID,
  setPbID,
  pbCellNumber,
  setPbCellNumber,
  pbIdData,
  setPbIdData,
  pbAge,
  setPbAge,
  nlTitle,
  setNlTitle,
  nlFullNames,
  setNlFullNames,
  nlSurname,
  setNlSurname,
  nlIDNumber,
  setNlIDNumber,
  nlCellNumber,
  setNlCellNumber,
  nlIdData,
  setNlIdData,
  pbLandline,
  setPbLandline,
  pbRelationship,
  setPbRelationship,
  nlLandline,
  setNlLandline,
  nlRelationship,
  setNlRelationship,
  nlAge,
  setNlAge
}) => {
  const [showNext, setShowNext] = useState(false);
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  // const validateID = (id) => {
  //   if (id) {
  //     return typeof (id) != "boolean" && !isNaN(id)
  //   }
  // }

  // const checkCellNumber = (number) => {
  //   if (number.charAt(0) === '0') {
  //     if (number.length > 10) {
  //       setError('Please give a valid cell number')
  //       return false
  //     } else if (number.length < 10) {
  //       setError('Please give a valid cell number')
  //       return false
  //     } else {
  //       console.log('Your number is exactly ten')
  //       return true
  //     }
  //   }
  //   if (number.charAt(0) === '2') {
  //     if (number.length > 11) {
  //       setError('Please give a valid cell number')
  //       return false
  //     } else if (number.length < 11) {
  //       setError('Please give a valid cell number')
  //       return false
  //     } else {
  //       console.log('Your number is exactly ten')
  //       return true
  //     }
  //   }
  // }

  // const checkEmail = (email) => {
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  //     return (true)
  //   }
  //   setError("You have entered an invalid email address!")
  //   return (false)
  // }

  const requiredSubmitted = () => {
    // if (pbTitle != '') {
    //   if (pbAge != '') {
    //     if (pbID != '') {
    //       if (validateID(pbID)) {
    //         if (pbFullNames != '') {
    //           if (pbSurname != '') {
    //             if (pbCellNumber != '') {
    //               if (checkCellNumber(pbCellNumber)) {
    setShowNext(true)
    setError('Please check that all info is correct then click next')
    //               } else {
    //                 setError('Please give a valid cell number')
    //               }
    //             } else {
    //               setShowNext(true)
    //               setError('Please check that all info is correct then click next')
    //             }
    //           } else {
    //             setError('You have not submitted your Beneficiary Surname')
    //           }
    //         } else {
    //           setError('You have not submitted your Primary Beneficiary Names')
    //         }
    //       } else {
    //         setError('You have not used a numeric only for your Primary Beneficiary ID or Date of Birth')
    //       }
    //     } else {
    //       setError('You have not submitted your Primary Beneficiary ID or Date of Birth')
    //     }
    //   } else {
    //     setError('You have not submitted your Primary Beneficiary Age')
    //   }
    // } else {
    //   setError('You have not submitted your Primary Beneficiary Title')
    // }
  }

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const generateDOB = (value, field) => {
    let dob = '';
    const strlenth = (value.length - 1);
    for (var i = 0; i < strlenth; i++) {
      if (i < (strlenth - 6)) {
        dob += value[i];
      }
    }
    if (dob) {
      let year;
      let month = String(dob).substring(2, 4);
      let day = String(dob).substring(4, 6);
      const firstTwoChars = String(dob).substring(0, 2);
      if (firstTwoChars >= 45) year = "19" + firstTwoChars;
      else year = "20" + firstTwoChars;
      let finalDOB = year + "-" + month + "-" + day;
      getAge(finalDOB, field);
    }
  }

  const getAge = (dateString, field) => {
    var now = new Date();
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var dob = new Date(dateString);
    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearAge = "";

    yearAge = yearNow - yearDob;
    var monthAge;
    if (monthNow >= monthDob) monthAge = monthNow - monthDob;
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }

    var dateAge;
    if (dateNow >= dateDob) dateAge = dateNow - dateDob;
    else {
      monthAge--;
      dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    if (dateAge === 0) dateAge = 1;
    if (monthAge === 0) monthAge = 1;

    age = { years: yearAge, months: monthAge, days: dateAge };
    if (age.years > 0 && age.months > 0 && age.days > 0) ageString = age.years;
    else if (age.years === 0 && age.months > 0 && age.days > 0)
      ageString = "0." + age.months;

    if (field === "pbAge") setPbAge(ageString);
    else setNlAge(ageString)
  };

  return (
    <>
      <div className="page">
        <h6 style={{ fontWeight: '600', marginTop: '25px', textAlign: 'center', color: '#BB1A1B', marginBottom: '25px' }}>5. Primary Beneficiary Details</h6>
        <div style={{ marginBottom: '5vw', marginTop: '20px' }}>
          <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'center' }} onClick={handleShow}>Beneficiary Guidance</Button>
        </div>
        <Form style={{ marginBottom: '5vh' }}>
          <Form.Group className='mb-2' controlId='pbTitle'>
            <Form.Label className='Label' >Primary Beneficiary Title</Form.Label>
            <Form.Control className='Control' type='text' placeholder={pbTitle} onChange={(e) => setPbTitle(e.target.value)} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='pbFullNames'>
            <Form.Label className='Label' >Primary Beneficiary Full Names</Form.Label>
            <Form.Control className='Control' type='text' placeholder={pbFullNames} onChange={(e) => setPbFullNames(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='pbSurname'>
            <Form.Label className='Label' >Primary Beneficiary Surname</Form.Label>
            <Form.Control className='Control' type='text' placeholder={pbSurname} onChange={(e) => setPbSurname(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>

          <Form.Group className='mb-2' controlId='pbCellNumber'>
            <Form.Label className='Label' >Primary Beneficiary Cell Number</Form.Label>
            <Form.Control className='Control' type='text' placeholder={pbCellNumber} onChange={(e) => setPbCellNumber(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>

          <Form.Group className='mb-2' controlId='pbLandline'>
            <Form.Label className='Label' >Primary Beneficiary Landline</Form.Label>
            <Form.Control className='Control' type='text' placeholder={pbLandline} onChange={(e) => setPbLandline(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='pbRelationship'>
            <Form.Label className='Label' >Primary Beneficiary Relationship</Form.Label>
            <Form.Control className='Control' type='text' placeholder={pbRelationship} onChange={(e) => setPbRelationship(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>

          <Form.Group className='mb-2' controlId='pbID'>
            <Form.Label className='Label' >Primary Beneficiary ID</Form.Label>
            <Form.Control className='Control' type='text' placeholder={pbID} onChange={(e) => { setPbID(e.target.value); generateDOB(e.target.value, "pbAge") }} style={{ marginTop: '20px' }} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='pbAge'>
            <Form.Label className='Label' >Primary Beneficiary Age</Form.Label>
            <Form.Control className='Control' type='text' placeholder={pbAge} value={pbAge} onChange={(e) => setPbAge(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>
          <FileInputComponent
            labelText="Upload ID"
            labelStyle={{ fontSize: 14, marginRight: '14px', fontWeight: '600' }}
            multiple={true}
            callbackFunction={(file_arr) => { setPbIdData(file_arr[0].base64) }}
            accept="image/png, image/jpg, image/jpeg"
            imagePreview={true}
            buttonComponent={<Button variant='secondary w-50'><span style={{ color: 'white', fontWeight: 600 }}> Click to Select</span></Button>}
            imageContainerStyle={{ textAlign: 'center' }}
          />

          <h6 style={{ fontWeight: '600', marginTop: '25px', textAlign: 'center', color: '#BB1A1B', marginBottom: '25px' }}>6. Next in Line Beneficiary Details</h6>
          <Form.Group className='mb-2' controlId='nlTitle'>
            <Form.Label className='Label' >Next in Line Beneficiary Title</Form.Label>
            <Form.Control className='Control' type='text' placeholder={nlTitle} onChange={(e) => setNlTitle(e.target.value)} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='nlFullNames'>
            <Form.Label className='Label' >Next in Line Beneficiary Full Names</Form.Label>
            <Form.Control className='Control' type='text' placeholder={nlFullNames} onChange={(e) => setNlFullNames(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='nlSurname'>
            <Form.Label className='Label' >Next in Line Beneficiary Surname</Form.Label>
            <Form.Control className='Control' type='text' placeholder={nlSurname} onChange={(e) => setNlSurname(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='nlIDNumber'>
            <Form.Label className='Label' >Next in Line Beneficiary ID</Form.Label>
            <Form.Control className='Control' type='text' placeholder={nlIDNumber} onChange={(e) => { setNlIDNumber(e.target.value); generateDOB(e.target.value, "nlAge") }} style={{ marginTop: '20px' }} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='nlAge'>
            <Form.Label className='Label' >Next in Line Beneficiary Age</Form.Label>
            <Form.Control className='Control' type='text' placeholder={nlAge} value={nlAge} onChange={(e) => setNlAge(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>

          <Form.Group className='mb-2' controlId='nlLandline'>
            <Form.Label className='Label' >Next in Line Beneficiary Landline</Form.Label>
            <Form.Control className='Control' type='text' placeholder={nlCellNumber} onChange={(e) => setNlLandline(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='nlRelationship'>
            <Form.Label className='Label' >Next in Line Beneficiary Relationship</Form.Label>
            <Form.Control className='Control' type='text' placeholder={nlCellNumber} onChange={(e) => setNlRelationship(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>
          <Form.Group className='mb-2' controlId='nlCellNumber'>
            <Form.Label className='Label' >Next in Line Beneficiary Cell Number</Form.Label>
            <Form.Control className='Control' type='text' placeholder={nlCellNumber} onChange={(e) => setNlCellNumber(e.target.value)} style={{ marginTop: '20px' }} />
          </Form.Group>
          <FileInputComponent
            labelText="Upload ID"
            labelStyle={{ fontSize: 14, marginRight: '14px', fontWeight: '600' }}
            multiple={true}
            callbackFunction={(file_arr) => { setNlIdData(file_arr[0].base64) }}
            accept="image/png, image/jpg, image/jpeg"
            imagePreview={true}
            buttonComponent={<Button variant='secondary w-50'><span style={{ color: 'white', fontWeight: 600 }}> Click to Select</span></Button>}
            imageContainerStyle={{ textAlign: 'center' }}
          />
        </Form>

        <div style={{ textAlign: 'center', padding: '2vw' }}><h6 style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>{error}</h6></div>
        <div style={{ textAlign: 'center' }}>
          <Link to='/4'>
            <Button variant='secondary w-25' style={{ fontWeight: '600', background: '#D0D0D0', border: 'none', float: 'left', marginTop: '10vw', marginLeft: '20vw', marginBottom: '10vh' }}>BACK</Button>
          </Link>
          {showNext && <Link to='/6'>
            <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginTop: '10vw', marginRight: '20vw', marginBottom: '10vh' }} >NEXT</Button>
          </Link>}
          {!showNext && <Button variant='danger w-25' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginTop: '10vw', marginRight: '20vw', marginBottom: '10vh' }} onClick={requiredSubmitted} >Submit</Button>}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered backdrop={false} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Nomination of Beneficiaries:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Primary:</p>
          <p>1) Mean the person, legal entity or fund nominated by the Insured on the application form to receive the Benefits in the event of the death of the Insured.</p>
          <p>2) The beneficiary can be changed at any time by the main life Insured.</p>
          <p>3) Must have a valid South African Bank Account.</p>
          <p>Secondary:</p>
          <p>1) Back up beneficiary should the primary beneficiary pre-decease the Principal Member.</p>
          <p>What happens if you die without a nominated beneficiary or your beneficiary predeceases you?</p>
          <ul>
            <li>You can nominate a contingent (backup) beneficiary. It is important to note that the contingent beneficiary will only receive the benefit payment if your primary (first nominated) beneficiary predeceases you. </li>
            <li>Your funeral benefit will be paid following our scheme rules or it will be paid to your estate where it could take years for the legal processes to be wrapped up.</li>
          </ul>
          <p>The cover on your policy is paid out upon your death to cover funeral expenses Your nominated beneficiary should be someone you can rely on to handle your funeral arrangements.
          </p>
          <p>What happens if you nominate an underaged (minor) child as a beneficiary?  </p>
          <ul><li>Your funeral benefit will be paid into a trust and will not be immediately accessible to cover your funeral expenses.</li></ul>
          <p>What happens if you nominate a beneficiary of advanced age?</p>
          <ul>
            <li>It is very likely that your nominated beneficiary dies, before you and you will have to contact your broker (SA Army Foundation) to update your beneficiary details.</li>
            <li>If you pass on before the beneficiary of advanced age, you need to consider that your nominated beneficiary might not be able to handle the funeral arrangement due to age and/or ill-health</li>
          </ul>
          <p>What happens if you nominate a beneficiary who is mentally incapacitated (Disabled)?</p>
          <ul>
            <li>If a person is not able to understand his or her responsibilities and rights under the agreement, then they lack the mental capacity to form a contract.</li>
            <li>a person who is mentally incapacitated may not contract and therefore has no legal standing to contract. In short, such persons cannot be part of a contract.</li>
            <li>Your funeral benefit will be paid into your estate account and will not be available immediately for your funeral</li>
            <li>Your beneficiary might lose his/her government grant. </li>
          </ul>
          <p>Important Note: You can change your beneficiary at any time, this must be done in writing to the insurer, and it is important to note that a will document is not sufficient to change a beneficiary nomination.</p>
          <p>In the event your will and funeral beneficiaries do not match, the nominated beneficiary on your funeral policy will win out every time. Remember, life insurance is a contract and will be enforced as it is written.</p>
        </Modal.Body>
      </Modal>

    </>
  )
}
export default Five